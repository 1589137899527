<!--https://github.com/Aymkdn/v-snackbars/blob/master/v-snackbars.vue-->

<template>
	<div>
		<v-snackbar
			v-for="(snackbar,idx) in snackbars"
			v-bind="$attrs"
			timeout="-1"
			:top="isTop"
			:bottom="isBottom"
			:left="isLeft"
			:right="isRight"
			v-model="snackbar.show"
			:transition="snackbar.transition"
			:color="snackbar.color"
			:key="snackbar.key"
			:ref="'snackbar-'+snackbar.key"
			:class="'v-snackbars popup-'+instanceId+'-'+snackbar.key"
		>
			<template v-slot:default>
				<slot :message="snackbar.message">{{ snackbar.message }}</slot>
			</template>
			<template v-slot:action>
				<slot name="action" :close="() => removeMessage(snackbar.key)" :id="snackbar.key" :index="idx" :message="snackbar.message">
					<v-btn text @click="removeMessage(snackbar.key)">close</v-btn>
				</slot>
			</template>
		</v-snackbar>
		<css-style :key="snackbar.key+idx" v-for="(snackbar,idx) in snackbars">
			.v-snackbars.popup-{{instanceId}}-{{snackbar.key}} .v-snack__wrapper {
				transition: {{topOrBottom}} 500ms;
				{{topOrBottom}}: 0;
			}
			.v-snackbars.popup-{{instanceId}}-{{snackbar.key}} > .v-snack__wrapper {
				{{topOrBottom}}:{{ indexPosition[snackbar.key] * distances[snackbar.key] }}px;
			}
		</css-style>
	</div>
</template>

<script>
'use strict'

export default {
	inheritAttrs: false,
	props: {
		orientation : {
			type : String,
			default : "b"
		},
		timeout: {
			type : [Number, String],
			default : 4000
		}, 
		distance: {
			type: [Number, String],
			default: 55
		}
	},
	data() {
		return {
			snackbars: [], // array of {key, message, show(true)}
			distances: {}, // height of each snackbar to correctly position them
			instanceId: Date.now() + (Math.random() + "").slice(2) // to avoid multiple instance of popup system in project
		};
	},
	components: {
		"css-style": {
			render: function(createElement) {
				return createElement("style", this.$slots.default);
			}
		}
	},
	computed: {
		isLeft()    { return this.orientation.includes("l"); },
		isRight()   { return this.orientation.includes("r"); },
		isTop()     { return this.orientation.includes("t"); },
		isBottom()  { return this.orientation.includes("b"); },
		topOrBottom() { return this.isTop ? 'top' : 'bottom'; },
		indexPosition() {
			let ret = {};
			let idx = { counter : 0 };
			this.snackbars.forEach(o => ret[o.key] = idx.counter++);
			return ret;
		},
	},
	watch: {
		snackbars() {
			// retrieve the height for each snackbar
			this.$nextTick(function() {
				let ret = {};
				let len = this.snackbars.length;
				this.snackbars.forEach((o, idx) => {
					let distance = this.distance;
					if (idx + 1 < len) {
						let nextKey = this.snackbars[idx + 1].key;
						let elem = document.querySelector(".popup-" + this.instanceId + "-" + o.key);
						if (elem) {
							let wrapper = elem.querySelector(".v-snack__wrapper");
							if (wrapper) {
								distance = wrapper.clientHeight + 7;
							}
						}
						ret[nextKey] = distance;
					}
				});
				this.distances = ret;
			});
		}
	},
	methods: {
		addEvent({success = true, text }){
			let index = this.snackbars.length;
			let key = index + "-" + Date.now();
			let top = this.isTop;
			let bottom = this.isBottom;
			let right = this.isRight;

			// by default, it will be at the bottom
			if (!bottom && !top) bottom = true;
			
			let snackbar = {
				key: key,
				message: text,
				color: success ? 'success' : 'error',
				transition: (right ? 'slide-x-reverse-transition' : 'slide-x-transition'),
				show: false
			};
			this.snackbars.push(snackbar);
			this.$nextTick(function() {
				snackbar.show = true; // to see the come-in animation            
				if (this.timeout > 0) {
					setTimeout(() => this.removeMessage(snackbar.key), this.timeout * 1);
				}
			})
		},
		removeMessage(key) {
			let idx = this.snackbars.findIndex(s => s.key === key);
			if (idx < 0) return;

			this.snackbars[idx].show = false;
			let removeSnackbar = () => {
				let idx = this.snackbars.findIndex(s => s.key === key);
				if (idx >= 0) this.snackbars.splice(idx, 1);
			}
			// use a timeout to ensure the 'transitionend' will be triggerred
			let timeout = setTimeout(removeSnackbar, 600);
			// wait the end of the animation
			this.$refs['snackbar-'+key][0].$el.addEventListener('transitionend', () => {
				clearTimeout(timeout);
				removeSnackbar();
			}, { once: true });
		},
	}
}
</script>