import Base, { network } from "./Base";

export default class InfoService extends Base {
	constructor() {
		super();
	}


	getCompanyDetail(request) {
		return network.get(this.prefixUrl + "/site-settings", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetCompanyInfoDetail'));
	}

	getCompanyInfo(request) {
		return network.get(this.prefixUrl + "/site-information", request, null, { noAuth: true }).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetCompanyInfo'));
	}

	setCompanyInfo(request) {
		return network.post(this.prefixUrl + "/site-settings", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorSetCompanyInfo'));
	}

	getPersonalInfo(request) {
		return network.get(this.prefixUrl + "/users/profile", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetPersonalInfo'));
	}

	setPersonalInfo(request) {
		return network.put(this.prefixUrl + "/users/profile", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorSetPersonalInfoDetail'));
	}
}