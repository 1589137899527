import Base, { network } from "./Base";

export default class VehicleService extends Base {
	constructor() {
		super();
	}


	getList(request) {
		return network.post(this.prefixUrl + "/vehicles/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetVehicle'));
	}

	add(request) {
		return network.post(this.prefixUrl + "/vehicles", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddVehicle'));
	}

	update(request) {
		return network.put(this.prefixUrl + "/vehicles/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyVehicle'));
	}

	delete(request) {
		return network.post(this.prefixUrl + "/vehicles/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteVehicle'));
	}
}