import Base, { network } from "./Base";

export default class AccessControlService extends Base {
    constructor() {
        super();
    }

    getMeta(request) {
        return network.get(this.prefixUrl + "/access-control-list/list", request).then(response => {
            return response.data;
        }, error => this.handleError(error, 'errorGetAccessControlMeta'));
    }

    getList(request) {
        return network.post(this.prefixUrl + "/access-control-list/filter", request).then(response => {
            return response.data;
        }, error => this.handleError(error, 'errorGetAccessControl'));
    }

    add(request) {
        return network.post(this.prefixUrl + "/access-control-list", request).then(response => {
            return response.data;
        }, error => this.handleError(error, 'errorAddAccessControl'));
    }

    update(request) {
        return network.put(this.prefixUrl + "/access-control-list/" + request.id, request).then(response => {
            return response.data;
        }, error => this.handleError(error, 'errorModifyAccessControl'));
    }

    delete(request) {
        return network.post(this.prefixUrl + "/users/destroys", request).then(response => {
            return response.data;
        }, error => this.handleError(error, 'errorDeleteAccessControl'));
    }
}