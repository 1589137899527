import Base, { network } from "./Base";

export default class BillingService extends Base {
	constructor() {
		super();
	}

	getCompanyList(request) {
		return network.get(this.prefixUrl + "/invoices/statement/companies", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetInvoiceStatement'));
	}

	getStatements(request) {
		return network.post(this.prefixUrl + "/invoices/statement/preview", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetInvoiceStatement'));
	}

	sendStatement(request) {
		return network.post(this.prefixUrl + "/invoices/statement/send", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorSendInvoiceStatement'));
	}

}