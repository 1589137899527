import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import RouteTree from "./RouteTree";

const treeItems = [
  { path: "/", component: "SignIn" },
  { path: "*", component: "NotFound" },
  {
    path: "/portal",
    component: "Portal",
    meta: {
      requiresAuth: true,
      groups: [
        { key: "settings", icon: "mdi-hammer-screwdriver", title: "payGroupSettings", order: 12 },
        { key: "bill", icon: "mdi-clipboard-check-multiple", title: "pageBilling", order: 3 },
      ],
    },
    children: [
      { path: "/", name: "portal-empty", component: "Empty", meta: { requiresAuth: true, icon: "mdi-home", title: "pageHome", order: 0 } },
      {
        path: "order",
        component: "Order",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-thumb-up-outline", title: "pageOrder", order: 1, permission: ["order", "vip"] },
      },
      {
        path: "order_project",
        component: "OrderProject",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-thumb-up-outline", title: "pageCustomerProject", order: 1, permission: ["order", "vip"] },
      },
      {
        path: "fee_statement",
        component: "FeeStatement",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-clipboard-text", title: "pageFeeStatement", order: 2, permission: ["show_fee_statement"] },
      },
      {
        path: "invoice",
        component: "InvoiceStatement",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-deskphone", group: "bill", title: "pageBilling", order: 3, permission: ["billing"] },
      },
      {
        path: "fees_driver",
        component: "FeesDriver",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-deskphone", group: "bill", title: "pageDriverFees", order: 3, permission: ["billing"] },
      },
      {
        path: "total_year_bill",
        component: "TotalYearBill",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-deskphone", group: "bill", title: "pageTotalYearBill", order: 3, permission: ["billing"] },
      },
      {
        path: "total_fees_driver",
        component: "TotalFeesDriver",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-deskphone", group: "bill", title: "pageTotalFeesDriver", order: 3, permission: ["billing"] },
      },
      {
        path: "in_out_record",
        component: "InOutRecord",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-deskphone", group: "bill", title: "pageInOutRecord", order: 3, permission: ["billing"] },
      },

      {
        path: "import_order",
        component: "ImportOrder",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-database-import-outline", title: "pageImportOrder", order: 7, permission: ["customer"] },
      },
      {
        path: "customer",
        component: "Customer",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-human-child", title: "pageCustomer", order: 7, permission: ["customer"] },
      },
     
      {
        path: "driver",
        component: "Driver",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-ninja", title: "pageDriver", order: 8, permission: ["driver"] },
      },
      {
        path: "vehicle",
        component: "Vehicle",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-van-utility", title: "pageVehicle", order: 9, permission: ["vehicle"] },
      },
      {
        path: "access_control",
        component: "AccessControl",
        meta: { requiresAuth: true, loadPath: "functional", icon: "mdi-lock-outline", title: "pageAccessControl", order: 10, permission: ["access_control"] },
      },
      {
        path: "personal_info",
        component: "PersonalInfo",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-account", title: "pagePersonalInfo" },
      },
      {
        path: "address_book",
        component: "AddressBook",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-bookmark", title: "pageAddressBook", permission: ["show_address_book"] },
      },
      {
        path: "company_info",
        component: "CompanyInfo",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-home-map-marker", title: "pageCompanyInfo", permission: ["settings"] },
      },
      {
        path: "email_bill",
        component: "EmailBillTemplate",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-bank", title: "pageEmailBillTemplate", permission: ["settings"] },
      },
      {
        path: "email_invoice",
        component: "EmailInvoiceTemplate",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-email-variant", title: "pageEmailInvoiceTemplate", permission: ["settings"] },
      },
      {
        path: "payment_method",
        component: "PayMethod",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-credit-card", title: "pagePayMethod", permission: ["settings"] },
      },
      {
        path: "fee_type",
        component: "FeeType",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-currency-usd", title: "pageFeeType", permission: ["settings"] },
      },
      {
        path: "vehicle_type",
        component: "VehicleType",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-bus", title: "pageVehicleType", permission: ["settings"] },
      },
      {
        path: "job_type",
        component: "JobType",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-folder-multiple", title: "pageJobType", permission: ["settings"] },
      },
      {
        path: "recycle_order",
        component: "RecycleOrder",
        meta: { requiresAuth: true, loadPath: "functional", group: "settings", icon: "mdi-recycle", title: "pageRecycleOrder", permission: ["settings"] },
      },
    ],
  },
];
export const Tree = new RouteTree(treeItems);

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: Tree.getRoute(),
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.api.authenticated()) {
    store.dispatch("api/signOut");
    next({
      path: "/",
      //query: {redirect: to.fullPath}
    });
    return;
  }
  next(); // make sure to always call next()!
});

export default router;
