import Vue from "vue";
import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import config from "@/config";

import zhHans from 'vuetify/lib/locale/zh-Hans'
import zhHant from 'vuetify/lib/locale/zh-Hant'
import en from 'vuetify/lib/locale/en'
import LocaleSwitcher from "@/i18n/LocaleSwitcher";
import { i18nData } from "@/i18n";

Vue.use(Vuetify)

function findVueLocale() {
    let locale = LocaleSwitcher.getLocale() ?? config.preferred_locale;
    return i18nData.find(s => s.locale == locale).msg.__vuetify;
}

const opts = {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true // use custom color
        },
        themes: {
            light: config.theme,
            dark: config.theme,
        }
    },
    lang: {
        locales: { zhHans, zhHant, en },
        current: findVueLocale()
    }
}

export default new Vuetify(opts)