import Base, { network } from "./Base";

export default class AuthService extends Base {
	constructor() {
		super();
	}

	signOut() {
		return new Promise((resolve) => {
			network.setToken(null)
			resolve()
		})
	}

	signIn(request) {
		return network.post(this.prefixUrl + "/login", request, null, { noAuth: true }).then(response => {
			if (response?.data?.data?.access_token === undefined) throw new Error('errorNetworkTokenProblem')
			network.setToken(response.data.data.access_token, response.data?.data?.expires_in)
			return response
		}, error => this.handleError(error, 'errorSignIn'));
	}

	getPermission(request) {
		return network.get(this.prefixUrl + "/users/profile", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetPermission'));
	}

	changePassword(request) {
		return network.post(this.prefixUrl + "/users/reset-password", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorChangePersonalPassword'));
	}

	forceDriverPassword(request) {
		return network.post(this.prefixUrl + "/users/force-reset-password/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorChangeDriverPassword'));
	}

	forceAccessControlPassword(request) {
		return network.post(this.prefixUrl + "/users/force-reset-password/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorChangeAccessControlPassword'));
	}

	forceCustomerPassword(request) {
		return network.post(this.prefixUrl + "/users/force-reset-password/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorChangeCustomerPassword'));
	}
}