import Base, { network } from "./Base";

export default class OrderService extends Base {
	constructor() {
		super();
	}


	getList(request) {
		return network.post(this.prefixUrl + "/orders/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetOrder'));
	}

	add(request) {
		return network.post(this.prefixUrl + "/orders", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddOrder'));
	}

	update(request) {
		return network.put(this.prefixUrl + "/orders/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyOrder'));
	}

	delete(request) {
		return network.post(this.prefixUrl + "/orders/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteOrder'));
	}
	recycle(request) {
		return network.post(this.prefixUrl + "/orders/recycle", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorRecycleOrder'));
	}

	updateFee(request) {
		return network.post(this.prefixUrl + "/orders/" + request.id + "/fees/sync", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyOrderFee'));
	}

	updatePaid(request) {
		return network.put(this.prefixUrl + "/orders/" + request.id + "/is-paid", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyOrderPaid'));
	}

	updateCompleted(request) {
		return network.put(this.prefixUrl + "/orders/" + request.id + "/is-completed", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyOrderCompleted'));
	}

	getDriverVehicles(request) {
		return network.get(this.prefixUrl + "/orders/" + request.id + "/driver-vehicle/list", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetOrderDriverVehicle'));
	}

	detachDriverVehicle(request) {
		return network.post(this.prefixUrl + "/orders/" + request.id + "/driver-vehicle/detach", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDetachOrderDriverVehicle'));
	}

	attachDriverVehicle(request) {
		return network.post(this.prefixUrl + "/orders/" + request.id + "/driver-vehicle/attach", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAttachOrderDriverVehicle'));
	}

	getDocuments(request) {
		return network.get(this.prefixUrl + "/orders/" + request.id + "/documents", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetOrderDocument'));
	}

	

	uploadFile(request) {
		return network.post(this.prefixUrl + "/orders/" + request.id + "/documents/upload", request.form, {
			'Content-Type': 'multipart/form-data'
		}).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorUploadOrderDocument'));
	}

	deleteFile(request) {
		return network.delete(this.prefixUrl + "/orders/" + request.order_id + "/documents/" + request.file_id).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteOrderDocument'));
	}

	downloadFile(request) {
		return network.get(request.path, null, null, null, { responseType: 'blob', noPrefix: true }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorDownloadOrderDocument'));
	}

	getInvoice(request) {
		return network.get(this.prefixUrl + "/orders/" + request.id + "/invoices/preview", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetOrderInvoice'));
	}

	sendInvoice(request) {
		return network.post(this.prefixUrl + "/orders/" + request.id + "/invoices/send", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorSendOrderInvoice'));
	}

	downloadImportTemplate() {
		return network.get(this.prefixUrl + "/orders/import-template/download", null, null, null, { responseType: 'blob' }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorDownloadOrderImportTemplate'));
	}

	downloadBatchImportTemplate() {
		return network.get(this.prefixUrl + "/orders/batch-import-template/download", null, null, null, { responseType: 'blob' }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorDownloadOrderBatchImportTemplate'));
	}

	uploadImport(request) {
		return network.post(this.prefixUrl + "/orders/import", request.form, {
			'Content-Type': 'multipart/form-data'
		}).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorUploadImportOrder'));
	}

	uploadBatchImport(request) {
		return network.post(this.prefixUrl + "/orders/batch-import", request.form, {
			'Content-Type': 'multipart/form-data'
		}).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorUploadBatchImportOrder'));
	}

	downloadExport(request) {
		return network.post(this.prefixUrl + "/orders/export", request, null, { responseType: 'blob' }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorExportOrder'));
	}

	/**
	 * Author: william
	 * Name:   get fees driver api
	 * Note:   
	 * Time:   2022/06/15 14:58
	 */
	getFeesDriver(request){
		return network.post(this.prefixUrl + "/fees_driver",request).then(response => response.data,error => this.handleError(error,'errorGetDriverFees'))
	}

	/**
	 * Author: william
	 * Name:   get total fees driver api
	 * Note:   
	 * Time:   2022/06/21 14:12
	 */
	getTotalFeesDriver(request){
		return network.post(this.prefixUrl + "/fees_driver_total",request).then(response => response.data,error => this.handleError(error,'errorGetDriverFees'))
	}

	/**
	 * Author: william
	 * Name:   get total year
	 * Note:   
	 * Time:   2022/06/17 15:38
	 */
	getTotalYear(request){
		return network.post(this.prefixUrl + "/total_year",request).then(response => response.data,error => this.handleError(error,'errorGetDriverFees'))
	}

	/**
	 * Author: william
	 * Name:   download export for driver fees
	 * Note:   
	 * Time:   2022/06/16 14:52
	 */
	downloadExportDriverFees(request){
		return network.post(this.prefixUrl + "/export_fees_driver", request, null, { responseType: 'blob' }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorExportOrder'));
	}
	/**
	 * Author: william
	 * Name:   download export for total year
	 * Note:   
	 * Time:   2022/06/20 10:09
	 */
	downloadExportTotalYear(request){
		return network.post(this.prefixUrl + "/export_total_year", request, null, { responseType: 'blob' }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorExportOrder'));
	}

	/**
	 * Author: william
	 * Name:   download export for total fees driver
	 * Note:   
	 * Time:   2022/06/21 15:01
	 */
	downloadExportTotalFeesDriver(request){
		return network.post(this.prefixUrl + "/export_total_fees_driver", request, null, { responseType: 'blob' }).then(response => {
			let data = response?.data
			if (data === undefined) throw new Error('Data is undefined')
			return data;
		}, error => this.handleError(error, 'errorExportOrder'));
	}

	/**
	 * Author: william
	 * Name:   import excel order
	 * Note:   
	 * Time:   2022/06/28 14:59
	 */
	importExcelOrder(request){
		return network.post(this.prefixUrl + "/orders/import_excel_order",request).then(response => response.data,error => this.handleError(error,'errorAddOrder'))
	}
}