import VueCookie from "vue-cookie"
import config from "@/config"

export default class Cookie {
    _mode
    _expiredTime

    constructor(mode = "cookie", expiredTime = 1800) {
        if (!config.cookie_enabled) {
            mode = "localStorage"
        }
        this._mode = mode
        this._expiredTime = expiredTime
    }

    _getKey(key) {
        return config.cookie_key + "_" + key
    }

    setCookie(key, value, expireTime = this._expiredTime) {
        key = this._getKey(key)
        if (this._mode == "cookie") {
            VueCookie.set(key, value, expireTime + "s")
        } else {
            localStorage.setItem(key, value)
        }
    }

    getCookie(key) {
        key = this._getKey(key)
        if (this._mode == "cookie") {
            return VueCookie.get(key)
        }
        return localStorage.getItem(key)
    }

    deleteCookie(key) {
        let value = this.getCookie(key)
        if (value == null) return false;
        key = this._getKey(key)
        if (this._mode == "cookie") {
            VueCookie.delete(key)
        } else {
            localStorage.removeItem(key)
        }

        return true
    }

}