import theme from "./theme"

const cookieEnabled = navigator.cookieEnabled
const cookieKey = "xtt"
const cookieExpireTime = 3600
const preferredLocale = "hk"

const apiVersion = "v1"
let apiUrl = ""
switch (process.env.NODE_ENV.trim()) {
    case "development":
        apiUrl = "http://xtreme.try.com/"
        break
    default:
        apiUrl = "http://xtt1.ectg.net/"
        break;
}

export default {
    preferred_locale: preferredLocale,
    local_storege_mode: cookieEnabled ? "cookie" : "localStorage",
    cookie_enabled: cookieEnabled,
    cookie_key: cookieKey,
    cookie_expire_time: cookieExpireTime,
    api_url: apiUrl,
    api_version: apiVersion,
    theme,
}