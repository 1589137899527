import Base, { network } from "./Base";

export default class AttributeService extends Base {
	constructor() {
		super();
	}


	getPaymentList(request) {
		return network.post(this.prefixUrl + "/settings/payment-methods/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetPaymentList'));
	}

	addPaymentMethod(request) {
		return network.post(this.prefixUrl + "/settings/payment-methods", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddPaymentMethod'));
	}

	updatePaymentMethod(request) {
		return network.put(this.prefixUrl + "/settings/payment-methods/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyPaymentMethod'));
	}

	deletePaymentMethod(request) {
		return network.post(this.prefixUrl + "/settings/payment-methods/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeletePaymentMethod'));
	}


	getJobTypeList(request) {
		return network.post(this.prefixUrl + "/settings/job-types/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetJobTypeList'));
	}

	addJobType(request) {
		return network.post(this.prefixUrl + "/settings/job-types", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddJobType'));
	}

	updateJobType(request) {
		return network.put(this.prefixUrl + "/settings/job-types/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyJobType'));
	}

	deleteJobType(request) {
		return network.post(this.prefixUrl + "/settings/job-types/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteFeeType'));
	}


	getFeeTypeList(request) {
		return network.post(this.prefixUrl + "/settings/fees/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetFeeTypeList'));
	}

	addFeeType(request) {
		return network.post(this.prefixUrl + "/settings/fees", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddFeeType'));
	}

	updateFeeType(request) {
		return network.put(this.prefixUrl + "/settings/fees/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyFeeType'));
	}

	deleteFeeType(request) {
		return network.post(this.prefixUrl + "/settings/fees/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteFeeType'));
	}


	getVehicleTypeList(request) {
		return network.post(this.prefixUrl + "/settings/vehicle-types/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetVehicleTypeList'));
	}

	addVehicleType(request) {
		return network.post(this.prefixUrl + "/settings/vehicle-types", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddVehicleType'));
	}

	updateVehicleType(request) {
		return network.put(this.prefixUrl + "/settings/vehicle-types/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyVehicleType'));
	}

	deleteVehicleType(request) {
		return network.post(this.prefixUrl + "/settings/vehicle-types/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteVehicleType'));
	}


	getAddressList(request) {
		return network.post(this.prefixUrl + "/company-address-books/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetAddressList'));
	}

	addAddress(request) {
		return network.post(this.prefixUrl + "/company-address-books", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddAddress'));
	}

	updateAddress(request) {
		return network.put(this.prefixUrl + "/company-address-books/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyAddress'));
	}

	deleteAddress(request) {
		return network.post(this.prefixUrl + "/company-address-books/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteAddress'));
	}
}