import Vue from "vue"
import VueI18n from "vue-i18n"
import config from "@/config"
import LocaleSwitcher from "./LocaleSwitcher"

import * as cn from "./cn.json"
import * as hk from "./hk.json"
import * as en from "./en.json"

const _i18nDataTmp = [cn, hk, en]

export const i18nData = _i18nDataTmp.map(item => {
    return {
        locale: item.__locale,
        network: item.__network,
        msg: item
    }
})


Vue.use(VueI18n)
export default new VueI18n({
    locale: LocaleSwitcher.getLocale() ?? config.preferred_locale,
    fallbackLocale: "en",
    messages: i18nData.reduce((item, current) => {
        item[current.locale] = current.msg
        return item
    }, {})
})

