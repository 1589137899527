import SessionTimedOutError from "./error/SessionTimedOutError";
import config from "@/config";
import Cookie from "@/plugins/cookie";
import LocaleSwitcher from "@/i18n/LocaleSwitcher";
import { i18nData } from "@/i18n";
import Network from "@/plugins/axios";

export default class Base {
    prefixUrl = "api/" + config.api_version
    constructor() { }

    handleError(error, message) {
        if (error instanceof SessionTimedOutError) throw error;
        let item = error?.response?.data?.data?.errors;
        if (item) {
            for (let key in item) {
                let val = item[key]
                if (val?.length > 0) throw new Error(val.shift())
            }
        }
        throw new Error(error?.response?.data?.message || error?.message || message)
    }

}

let cookie = new Cookie()

let tokenHandler = {
    _authenticationKey: "authentication_key",
    _expiredTimeKey: "authenticatie_expired_key",
    getToken() {
        let time = cookie.getCookie(this._expiredTimeKey)
        return {
            token: cookie.getCookie(this._authenticationKey),
            expired: time ? new Date(time) : new Date(new Date().getTime() + 1000 * 3600 * 24)
        }
    },
    setToken(token, duration) {
        let notEmpty = !!token
        if (notEmpty) {
            cookie.setCookie(this._authenticationKey, token);
            cookie.setCookie(this._expiredTimeKey, new Date(new Date().getTime() + duration * 1000))
        } else {
            cookie.deleteCookie(this._authenticationKey)
            cookie.deleteCookie(this._expiredTimeKey)
        }
    }
}

let localeHandler = {
    get() {
        let value = LocaleSwitcher.getLocale() ?? config.preferred_locale
        let v = i18nData.find(item => item.locale == value)
        return v?.network
    }
}

export const network = new Network(config.api_url, tokenHandler, localeHandler);