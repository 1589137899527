export default [
    { serverName: "PAGE.ORDER", permission: "order", i18nKey: "accessControlPermOrder" },
    { serverName: "PAGE.VIP_ORDER", permission: "vip", i18nKey: "accessControlPermVipOrder" },
    { serverName: "PAGE.IMPORT_REPORT", permission: "import_report", i18nKey: "accessControlPermImportReport" },
    { serverName: "PAGE.BILLING", permission: "billing", i18nKey: "accessControlPermBilling" },
    { serverName: "PAGE.REPORT", permission: "report", i18nKey: "accessControlPermReport" },
    { serverName: "PAGE.CLIENT_MANAGEMENT", permission: "customer", i18nKey: "accessControlPermCustomer" },
    { serverName: "PAGE.DRIVER_MANAGEMENT", permission: "driver", i18nKey: "accessControlPermDriver" },
    { serverName: "PAGE.VEHICLE_MANAGEMENT", permission: "vehicle", i18nKey: "accessControlPermVehicle" },
    { serverName: "PAGE.ACCESS_CONTROL", permission: "access_control", i18nKey: "accessControlPermAccessControl" },
    { serverName: "PAGE.SETTINGS", permission: "settings", i18nKey: "accessControlPermSettings" },
    { serverName: "PAGE.MAINTENANCE", permission: "maintenance", i18nKey: "accessControlPermMantenance" },
    { serverName: "PAGE.ADDRESS_BOOK", permission: "show_address_book", i18nKey: "accessControlPermAddressBook" },
]