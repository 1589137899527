import Base, { network } from "./Base";

export default class EmailTemplateService extends Base {
	constructor() {
		super();
	}


	getBill(request) {
		return network.get(this.prefixUrl + "/settings/email-templates/bill", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetEmailBillTemplate'));
	}

	setBill(request) {
		return network.put(this.prefixUrl + "/settings/email-templates/bill", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorSetEmailBillTemplate'));
	}

	getInvoice(request) {
		return network.get(this.prefixUrl + "/settings/email-templates/invoice", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetEmailInvoiceTemplate'));
	}

	setInvoice(request) {
		return network.put(this.prefixUrl + "/settings/email-templates/invoice", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorSetEmailInvoiceTemplate'));
	}
}