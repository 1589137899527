import Base, { network } from "./Base";

export default class DriverService extends Base {
	constructor() {
		super();
	}


	getList(request) {
		return network.post(this.prefixUrl + "/drivers/filter", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetDriver'));
	}

	add(request) {
		return network.post(this.prefixUrl + "/drivers", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorAddDriver'));
	}

	update(request) {
		return network.put(this.prefixUrl + "/drivers/" + request.id, request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorModifyDriver'));
	}

	delete(request) {
		return network.post(this.prefixUrl + "/drivers/destroys", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorDeleteDriver'));
	}

	getVehicles(request) {
		return network.get(this.prefixUrl + "/drivers/" + request.id + "/vehicles", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetDriverVehicles'));
	}

	changeVehicles(request) {
		return network.post(this.prefixUrl + "/drivers/" + request.id + "/vehicles/sync", request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorChangeDriverVehicles'));
	}

	getFeeList(request) {
		return network.post(this.prefixUrl + '/drivers/order-fees', request).then(response => {
			return response.data;
		}, error => this.handleError(error, 'errorGetDriverFeeList'));
	}
}