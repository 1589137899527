'use strict'

import Cookie from '@/plugins/cookie'

let cookie = new Cookie();

export default {
    _uiLayout: "uiLayout",
    defaultType(type) {
        let layout = {};
        layout.navigationDrawer = type == "driver";
        layout.orderForm = type == "driver";
        return layout;
    },
    getLayout(type) {
        let layout = cookie.setCookie(this._uiLayout)
        let notEmpty = !!layout;
        return notEmpty ? layout : this.defaultType(type);
    }
}