import { network } from "@/api/Base";
import permission_set from "@/config/permission_set";
import ui_layout from "@/config/ui_layout";

import AuthService from "@/api/AuthService";
import AttributeService from "@/api/AttributeService";
import InfoService from "@/api/InfoService";
import AccessControlService from "@/api/AccessControlService";
import VehicleService from "@/api/VehicleService";
import CustomerService from "@/api/CustomerService";
import EmailTemplateService from "@/api/EmailTemplateService";
import DriverService from "@/api/DriverService";
import OrderService from "@/api/OrderService";
import BillingService from "@/api/BillingService";
import InOutRecordService from "@/api/InOutRecordService";

export default class AuthStore {
  _authService = new AuthService();
  _attributeService = new AttributeService();
  _infoService = new InfoService();
  _emailTemplateService = new EmailTemplateService();
  _accessControlService = new AccessControlService();
  _vehicleService = new VehicleService();
  _customerService = new CustomerService();
  _driverService = new DriverService();
  _orderService = new OrderService();
  _billingService = new BillingService();
  _inOutRecordService = new InOutRecordService();

  constructor() {
    let current = this;

    this.namespaced = true;
    this.state = {
      authenticated: () => network.hasToken(),
      permission: null,
      roleType: null,
      companyInfo: null,
      uiSettings: null,
    };
    this.actions = {
      signIn: (commit, request) => current._signIn(commit, request),
      signOut: (commit) => current._signOut(commit),
      getCompanyInfo: (commit) => current._retrieveCompanyInfo(commit),
      retrieveData: (commit) => current._retrieveData(commit),

      changePersonalPassword: (_, request) => current._authService.changePassword(request),
      changeDriverPassword: (_, request) => current._authService.forceDriverPassword(request),
      changeAccessControlPassword: (_, request) => current._authService.forceAccessControlPassword(request),
      changeCustomerPassword: (_, request) => current._authService.forceCustomerPassword(request),

      getCompanyInfoDetail: (_, request) => current._infoService.getCompanyDetail(request),
      setCompanyInfo: (_, request) => current._infoService.setCompanyInfo(request),
      getPersonalInfo: (_, request) => current._infoService.getPersonalInfo(request),
      setPersonalInfo: (_, request) => current._infoService.setPersonalInfo(request),

      getAccessControlMetaList: (_, request) => current._accessControlService.getMeta(request),
      getAccessControlList: (_, request) => current._accessControlService.getList(request),
      addAccessControl: (_, request) => current._accessControlService.add(request),
      updateAccessControl: (_, request) => current._accessControlService.update(request),
      deleteAccessControl: (_, request) => current._accessControlService.delete(request),

      getVehicleList: (_, request) => current._vehicleService.getList(request),
      addVehicle: (_, request) => current._vehicleService.add(request),
      updateVehicle: (_, request) => current._vehicleService.update(request),
      deleteVehicle: (_, request) => current._vehicleService.delete(request),

      getCustomerList: (_, request) => current._customerService.getList(request),
      addCustomer: (_, request) => current._customerService.add(request),
      updateCustomer: (_, request) => current._customerService.update(request),
      deleteCustomer: (_, request) => current._customerService.delete(request),

      getOrderList: (_, request) => current._orderService.getList(request),
      addOrder: (_, request) => current._orderService.add(request),
      updateOrder: (_, request) => current._orderService.update(request),
      deleteOrder: (_, request) => current._orderService.delete(request),
      recycleOrder: (_, request) => current._orderService.recycle(request),
      updateOrderFee: (_, request) => current._orderService.updateFee(request),
      updateOrderPaid: (_, request) => current._orderService.updatePaid(request),
      updateOrderCompleted: (_, request) => current._orderService.updateCompleted(request),
      getOrderDriverVehicles: (_, request) => current._orderService.getDriverVehicles(request),
      detachOrderDriverVehicle: (_, request) => current._orderService.detachDriverVehicle(request),
      attachOrderDriverVehicle: (_, request) => current._orderService.attachDriverVehicle(request),
      getOrderDocument: (_, request) => current._orderService.getDocuments(request),
      uploadOrderFile: (_, request) => current._orderService.uploadFile(request),
      deleteOrderFile: (_, request) => current._orderService.deleteFile(request),
      downloadOrderFile: (_, request) => current._orderService.downloadFile(request),
      getOrderInvoice: (_, request) => current._orderService.getInvoice(request),
      sendOrderInvoice: (_, request) => current._orderService.sendInvoice(request),
      downloadOrderImportTemplate: (_, request) => current._orderService.downloadImportTemplate(request),
      downloadOrderBatchImportTemplate: (_, request) => current._orderService.downloadBatchImportTemplate(request),
      uploadImportOrder: (_, request) => current._orderService.uploadImport(request),
      uploadBatchImportOrder: (_, request) => current._orderService.uploadBatchImport(request),
      exportOrders: (_, request) => current._orderService.downloadExport(request),

      getDriverList: (_, request) => current._driverService.getList(request),
      addDriver: (_, request) => current._driverService.add(request),
      updateDriver: (_, request) => current._driverService.update(request),
      deleteDriver: (_, request) => current._driverService.delete(request),
      getDriverVechicles: (_, request) => current._driverService.getVehicles(request),
      changeDriverVehicles: (_, request) => current._driverService.changeVehicles(request),
      getDriverFeeList: (_, request) => current._driverService.getFeeList(request),

      getEmailBillTemplate: (_, request) => current._emailTemplateService.getBill(request),
      setEmailBillTemplate: (_, request) => current._emailTemplateService.setBill(request),
      getEmailInvoiceTemplate: (_, request) => current._emailTemplateService.getInvoice(request),
      setEmailInvoiceTemplate: (_, request) => current._emailTemplateService.setInvoice(request),

      getInvoiceStatementCompany: (_, request) => current._billingService.getCompanyList(request),
      getInvoiceStatement: (_, request) => current._billingService.getStatements(request),
      sendInvoiceStatement: (_, request) => current._billingService.sendStatement(request),

      getPaymentMethodList: (_, request) => current._attributeService.getPaymentList(request),
      addPaymentMethod: (_, request) => current._attributeService.addPaymentMethod(request),
      updatePaymentMethod: (_, request) => current._attributeService.updatePaymentMethod(request),
      deletePaymentMethod: (_, request) => current._attributeService.deletePaymentMethod(request),
      getJobTypeList: (_, request) => current._attributeService.getJobTypeList(request),
      addJobType: (_, request) => current._attributeService.addJobType(request),
      updateJobType: (_, request) => current._attributeService.updateJobType(request),
      deleteJobType: (_, request) => current._attributeService.deleteJobType(request),
      getFeeTypeList: (_, request) => current._attributeService.getFeeTypeList(request),
      addFeeType: (_, request) => current._attributeService.addFeeType(request),
      updateFeeType: (_, request) => current._attributeService.updateFeeType(request),
      deleteFeeType: (_, request) => current._attributeService.deleteFeeType(request),
      getVehicleTypeList: (_, request) => current._attributeService.getVehicleTypeList(request),
      addVehicleType: (_, request) => current._attributeService.addVehicleType(request),
      updateVehicleType: (_, request) => current._attributeService.updateVehicleType(request),
      deleteVehicleType: (_, request) => current._attributeService.deleteVehicleType(request),
      getAddressList: (_, request) => current._attributeService.getAddressList(request),
      addAddress: (_, request) => current._attributeService.addAddress(request),
      updateAddress: (_, request) => current._attributeService.updateAddress(request),
      deleteAddress: (_, request) => current._attributeService.deleteAddress(request),

      /**
       * Author: william
       * Name:   new api store
       * Note:
       */
      getFeesDriver: (_, request) => current._orderService.getFeesDriver(request),
      getTotalYear: (_, request) => current._orderService.getTotalYear(request),
      getTotalFeesDriver: (_, request) => current._orderService.getTotalFeesDriver(request),
      exportDriverFees: (_, request) => current._orderService.downloadExportDriverFees(request),
      exportTotalYear: (_, request) => current._orderService.downloadExportTotalYear(request),
      exportTotalFeesDriver: (_, request) => current._orderService.downloadExportTotalFeesDriver(request),

      getListRecord: (_, request) => current._inOutRecordService.get_list(request),
      addRecord: (_, request) => current._inOutRecordService.add(request),
      editRecord: (_, request) => current._inOutRecordService.edit(request),
      delRecord: (_, request) => current._inOutRecordService.del(request),
      exportRecord: (_, request) => current._inOutRecordService.downloadExport(request),

      exportCustomer: (_, request) => current._customerService.downloadExport(request),
      importExcelOrder: (_, request) => current._orderService.importExcelOrder(request),
    };
    this.mutations = {
      updatePermissionStatus: (state, permission) => (state.permission = permission),
      updateRoleType: (state, roleType) => (state.roleType = roleType),
      updateCompanyInfo: (state, companyInfo) => (state.companyInfo = companyInfo),
      updateUiSettings: (state, uiSettings) => (state.uiSettings = uiSettings),
    };
  }

  _signIn(_, request) {
    return this._authService.signIn(request).catch((err) => {
      this._authService.signOut();
      throw err;
    });
  }

  _signOut({ commit }) {
    return this._authService.signOut().then(() => {
      commit("updatePermissionStatus", null);
      commit("updateRoleType", null);
    });
  }

  _retrieveData({ commit }) {
    return Promise.all([this._authService.getPermission()]).then((values) => {
      const permData = values[0].data;

      let permission = {};
      for (const item of permission_set) {
        permission[item.permission] = permData.permissions.find((s) => s.name == item.serverName) != null;
      }
      let role = permData.roles[0].toLowerCase();
      if (role == "driver") permission["show_fee_statement"] = true;

      commit("updatePermissionStatus", permission);
      commit("updateRoleType", role);
      commit("updateUiSettings", ui_layout.getLayout(role));
    });
  }

  _retrieveCompanyInfo({ commit }) {
    return this._infoService.getCompanyInfo().then(({ data }) => {
      commit("updateCompanyInfo", data[0]);
    });
  }
}
