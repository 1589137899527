<template>
  <v-app id="app" :style="{ background: $vuetify.theme.themes.light.tertiary }">
    <router-view />
    <PopupMessage ref="popup" orientation="rb" />
  </v-app>
</template>

<script>
"use strict";

import PopupMessage from "@/components/PopupMessage.vue";

export default {
  name: "App",
  components: {
    PopupMessage,
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type == "ui/notified") {
        this.$refs.popup.addEvent(state.ui.messagePacket);
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  beforeCreate() {
    this.$store
      .dispatch("api/getCompanyInfo")
      .then(() => {
        document.title = this.$store.state?.api?.companyInfo?.company_name;
      })
      .catch((error) => {
        this.$store.dispatch("ui/notify", {
          success: false,
          text: error.message,
        });
      });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
