import Base, { network } from "./Base";

export default class CustomerService extends Base {
  constructor() {
    super();
  }

  getList(request) {
    return network.post(this.prefixUrl + "/companies/filter", request).then(
      (response) => {
        return response.data;
      },
      (error) => this.handleError(error, "errorGetCustomer")
    );
  }

  add(request) {
    return network.post(this.prefixUrl + "/companies", request).then(
      (response) => {
        return response.data;
      },
      (error) => this.handleError(error, "errorAddCustomer")
    );
  }

  update(request) {
    return network.put(this.prefixUrl + "/companies/" + request.id, request).then(
      (response) => {
        return response.data;
      },
      (error) => this.handleError(error, "errorModifyCustomer")
    );
  }

  delete(request) {
    return network.post(this.prefixUrl + "/companies/destroys", request).then(
      (response) => {
        return response.data;
      },
      (error) => this.handleError(error, "errorDeleteCustomer")
    );
  }

  downloadExport(request) {
    return network.post(this.prefixUrl + "/companies/export", request, null, { responseType: "blob" }).then(
      (response) => {
        let data = response?.data;
        if (data === undefined) throw new Error("Data is undefined");
        return data;
      },
      (error) => this.handleError(error, "errorExport")
    );
  }
}
