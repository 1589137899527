export default class UIStore {
	constructor() {
		this.namespaced = true
		this.state = {
			messagePacket: null
		}

		let current = this
		this.actions = {
			notify : (commit, message) => current.notify(commit, message)
		}
		this.mutations = {
			notified: (state, message) => current.notified(state, message)
		}
	}

	notify({commit}, message) {
		commit('notified', message)
	}

	notified(state, message) {
		state.messagePacket = message
	}
}