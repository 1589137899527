import Base, { network } from "./Base";

export default class InOutRecordService extends Base {
  constructor() {
    super();
  }

  get_list(request) {
    return network.post(this.prefixUrl + "/list_record", request).then(
      (response) => response.data,
      (error) => this.handleError(error, "errorGetInOutRecord")
    );
  }
  add(request) {
    return network.post(this.prefixUrl + "/add_record", request).then(
      (response) => response.data,
      (error) => this.handleError(error, "errorAddInOutRecord")
    );
  }
  edit(request) {
    return network.post(this.prefixUrl + "/edit_record", request).then(
      (response) => response.data,
      (error) => this.handleError(error, "errorEditInOutRecord")
    );
  }
  del(request) {
    return network.post(this.prefixUrl + "/del_record", request).then(
      (response) => response.data,
      (error) => this.handleError(error, "errorDelInOutRecord")
    );
  }
  downloadExport(request) {
    return network.post(this.prefixUrl + "/export_record", request, null, { responseType: "blob" }).then(
      (response) => {
        let data = response?.data;
        if (data === undefined) throw new Error("Data is undefined");
        return data;
      },
      (error) => this.handleError(error, "errorExportOrder")
    );
  }
}
