import Vue from 'vue'
import Vuex from 'vuex'

import UIStore from "./UIStore"
import ApiStore from "./ApiStore"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api: new ApiStore(),
    ui: new UIStore()
  }
})
