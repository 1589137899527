export default {
	primary: '#2c698d',
	secondary: '#bae8e8',
	// All keys will generate theme styles,
	// Here we add a custom `tertiary` color
	primaryLight: '#77b2d4',
	tertiary : '#FFFFB4',
	quaternary: '#FFFFFF',
	quinary : '#33cc33', 

	primaryInverse : '#FFFFFF', // this is used for text on primary color

	danger : '#E53935',
	success : '#3C6E71',
	error: '#E53935',
	background: '#D9D9D9',
}