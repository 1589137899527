
export default class RouteTree {
	constructor(treeItems) {
		this.items = treeItems;

		let updateFullPath = function (items, path) {
			items.forEach(item => {
				if (item.meta == null) item.meta = {}

				item.meta.fullPath = (path == null ? item.path : `${path}/${item.path === "/" ? '' : item.path}`);
				if (item.children != null) {
					updateFullPath(item.children, item.meta.fullPath);
				}

			})
		}
		updateFullPath(this.items, null);
	}


	getRoute() {
		let getLoop = function (route) {
			return {
				...route,
				name: (route.children != null && route.children.length != 0) ? null : (route.name == null ? route.component : route.name),
				component: () => import(`@/views/${route?.meta?.loadPath == null ? '' : route.meta.loadPath + '/'}${route.component}.vue`),
				children: route.children?.map(child => getLoop(child)),
			};
		}

		return this.items.map(route => getLoop(route));
	}

	getList() {
		let processLoop = function (items, arrayList) {
			for (let i = 0; i < items.length; i++) {
				arrayList.push(items[i]);
				if (items[i].children != null) {
					arrayList = processLoop(items[i].children, arrayList);
				}
			}
			return arrayList;
		}
		return processLoop(this.items, []);
	}

	getParent(path, level) {
		let array = path.split('/')
		return ('/'.concat(array.slice(1, level + 1).join('/')))
	}

	getSpecific(path, from, to) {
		let array = path.split('/')
		return ('/'.concat(array.slice(from + 1, to + 1).join('/')))
	}
}
