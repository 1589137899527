import axios from "axios";

export default class Network {
    _url
    _tokenHandler
    _localeHandler
    _expiredSecond

    constructor(url, tokenHandler, localeHandler, expiredSecond = 180) {
        this._url = url
        this._tokenHandler = tokenHandler
        this._localeHandler = localeHandler
        this._expiredSecond = expiredSecond
    }

    hasToken() {
        let data = this._tokenHandler.getToken()
        return data.token != null
    }

    setToken(token, duration = 100) {
        this._tokenHandler.setToken(token ? "Bearer " + token : null, duration)
    }

    _createConfig(tokenData, locale, headers, specific) {
        if (!specific?.noAuth && tokenData?.token != null) {
            if (headers == null) {
                headers = { Authorization: tokenData.token };
            } else {
                headers.Authorization = tokenData.token;
            }
        }
        if (locale != null) {
            if (headers == null) headers = {};
            headers['x-lang'] = locale;
        }

        let config = { headers }
        if (specific?.responseType != null) {
            config.responseType = specific.responseType;
        }
        return config;
    }

    post(path, data, headers = null, specific) {
        let tokenData = this._tokenHandler.getToken();
        let locale = this._localeHandler?.get();

        let promise = new Promise((resolve) => resolve());
        return promise
            .then(() => {
                return axios.post(this._url + path, data, this._createConfig(tokenData, locale, headers, specific));
            })
            .then(response => {
                if (response.status != null && response?.status == 'success') {
                    throw new Error(response?.message ?? 'errorNetworkRequestError');
                }
                return response;
            });
    }

    get(path, data, params = null, headers = null, specific) {
        let tokenData = this._tokenHandler.getToken();
        let locale = this._localeHandler?.get();

        let promise = new Promise((resolve) => resolve());
        return promise
            .then(() => {
                let axioConfig = this._createConfig(tokenData, locale, headers, specific);
                axioConfig.params = params == null ? {} : params;
                let url = (specific?.noPrefix ? "" : this._url) + path
                return axios.get(url, axioConfig);
            })
            .then(response => {
                if (response.status != null && response?.status == 'success') {
                    throw new Error(response?.message ?? 'errorNetworkRequestError');
                }
                return response;
            });
    }

    put(path, data, headers = null, specific) {
        let tokenData = this._tokenHandler.getToken();
        let locale = this._localeHandler?.get();

        let promise = new Promise((resolve) => resolve());
        return promise
            .then(() => {
                return axios.put(this._url + path, data, this._createConfig(tokenData, locale, headers, specific));
            })
            .then(response => {
                if (response.status != null && response?.status == 'success') {
                    throw new Error(response?.message ?? 'errorNetworkRequestError');
                }
                return response;
            });
    }

    delete(path, data, headers = null, specific) {
        let tokenData = this._tokenHandler.getToken();
        let locale = this._localeHandler?.get();

        let promise = new Promise((resolve) => resolve());
        return promise
            .then(() => {
                let axioConfig = this._createConfig(tokenData, locale, headers, specific);
                axioConfig.data = data;
                return axios.delete(this._url + path, axioConfig);
            })
            .then(response => {
                if (response.status != null && response?.status == 'success') {
                    throw new Error(response?.message ?? 'errorNetworkRequestError');
                }
                return response;
            });
    }


}