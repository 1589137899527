import config from "@/config";
import Cookie from "@/plugins/cookie";

class LocaleSwitcher {
    _cookie;
    _locale_key= "locale_key"

    constructor() {
        this._cookie = new Cookie();
    }

    getLocale() {
        return this._cookie.getCookie(this._locale_key);
    }

    setLocale(lang) {
        let notEmpty = !!lang;
        if (notEmpty) {
            this._cookie.setCookie(this._locale_key, lang)
        } else {
            this._cookie.deleteCookie(this._locale_key);
        }
    }
}

export default new LocaleSwitcher()
